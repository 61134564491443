.mainDiv {
  background: #EBE9F4;
  min-height: 100vh;
  font-size: 1.4vw;
}

.mainDiv span [class^="ant-checkbox-inner"] {
  width: 2vw;
  height: 2vw;

}

.emailInput input {
  height: 5vh;
  border-radius: 12px;
  margin-top: 12px;
  font-size: 1.2vw;
}


.generatorPage {
  background: #EBE9F4;
  min-height: 100vh;
}


.invoicePreview {
  height: 100%;
  background: white;
  border-radius: 16px;
}

.invoicePreview div[class ^="invoice-box"] {
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}