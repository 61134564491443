.collapse-panel-custom .ant-collapse-header {
  background-color: #f7f7fc;
}

.ant-tabs-tab {
  text-align: center;
}


.activity-collapse-panel-custom .ant-collapse-header {
  background-color: #D1D6DF;
  border-radius: 0 !important;
}

.activity-collapse-panel-custom .ant-collapse-content-active {
  background-color: #ECEFF3 !important;
  border-radius: 0 !important;
}