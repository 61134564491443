.heat-map-style-small {
  display: flex;
  justify-content: space-evenly;
}

.heat-map-style-small>svg {
  width: 230px;
}


.heat-map-style-large>svg {
  margin-top: 8px;
  max-height: 320px;
  width: 80%;
}

.side-legend-large {
  display: flex;
}

.side-legend-large div {
  margin-left: 8px;
}

.side-legend-large span {
  margin-left: 8px;
}

.react-calendar-heatmap rect {
  outline: none;
}